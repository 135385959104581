import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/atlassian/pipelines/agent/build/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"DM_Sans\",\"arguments\":[{\"weight\":[\"400\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"dmSans\"}");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Playfair_Display\",\"arguments\":[{\"weight\":[\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"playfairDisplay\"}");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/node_modules/next/font/google/target.css?{\"path\":\"src/app/fonts.ts\",\"import\":\"Work_Sans\",\"arguments\":[{\"weight\":[\"600\",\"700\"],\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"workSans\"}");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/app/[locale]/layout.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider"] */ "/opt/atlassian/pipelines/agent/build/src/lib/auth/providers/AuthProvider/AuthProvider.tsx");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/lib/googleTagManager/GoogleTagManagerInitializer.tsx");
;
import(/* webpackMode: "eager" */ "/opt/atlassian/pipelines/agent/build/src/styles/index.scss");
